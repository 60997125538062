import { handleUserLogin } from "@/server/actions/user/loginAndReg";
import { createNotificationToSuggestMagicMfaEnable } from "@/server/actions/user/notifications/create/magicSuggestMfaEnable";
import { validateHuman } from "@/server/actions/user/recaptcha";
import { saveUserInfo } from "@/utils/common";
import showToast from "@/utils/showToast";
import { RPCError, RPCErrorCode } from "magic-sdk";
import { Dispatch, FormEvent, SetStateAction } from "react";

export const handleMagicLogin = async (
  e: React.KeyboardEvent | React.MouseEvent | FormEvent<HTMLFormElement>,
  email: string,
  setEmail: Dispatch<SetStateAction<string>>,
  setEmailError: Dispatch<SetStateAction<boolean>>,
  setLoginInProgress: Dispatch<SetStateAction<boolean>>,
  magic: any,
  setOpenLoginModal: (openLoginModal: boolean) => void,
  setOpenNewUserTourModal: (openNewUserTourModal: boolean) => void,
  recaptcha: string,
  fingerprint?: string
) => {
  e?.preventDefault();

  if (
    !email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    setEmailError(true);
  } else {
    try {
      setLoginInProgress(true);
      setEmailError(false);

      const token = await magic?.auth.loginWithEmailOTP({ email });
      const metadata = await magic?.user.getInfo();

      if (!token || !metadata?.publicAddress) {
        throw new Error("Magic login failed");
      }
      // 1) Check for bots via reCAPTCHA
      const human = await validateHuman(recaptcha || "");

      // If bot, throw error
      if (!human) {
        console.log("Not human.");
        throw new Error("Not human.");
      }
      saveUserInfo(token, "EMAIL", metadata?.publicAddress);
      const result = await handleUserLogin({
        didToken: token || "",
        email,
        recaptcha,
        fingerprint,
      });
      setOpenLoginModal(false);
      setEmail("");
      const userInfo = await magic?.user.getInfo();
      if (!userInfo?.isMfaEnabled || userInfo?.isMfaEnabled === false) {
        await createNotificationToSuggestMagicMfaEnable();
      }
    } catch (e) {
      if (e instanceof RPCError) {
        switch (e.code) {
          case RPCErrorCode.MagicLinkFailedVerification:
          case RPCErrorCode.MagicLinkExpired:
          case RPCErrorCode.MagicLinkRateLimited:
          case RPCErrorCode.UserAlreadyLoggedIn:
            showToast({ message: e.message, type: "error" });
            break;
          default:
            showToast({
              message: "Something went wrong. Please try again",
              type: "error",
            });
        }
      }
    } finally {
      setLoginInProgress(false);
    }
  }
};
